import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import OpenSeaDragonViewer from "../../../../composants/image/OpenSeaDragonViewer"

import { obtenirPage } from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "tresorsCygneZoomEN" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function TresorsCygne(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const image = {
    xmlns: "http://schemas.microsoft.com/deepzoom/2009",
    Format: "jpg",
    Overlap: "1",
    Size: { Width: "12387", Height: "14628" },
    TileSize: "256",
    Url: "/ressources/deep-zoom/cygne/tuiles/",
  }

  const composantImageInteractive = <OpenSeaDragonViewer image={image} />

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          A mosaic of 180 mega pixels, 12 tiles and 31 hours of exposure of a
          rich region of the Cygnus constellation, taken during all summer 2020
          with a Canon EOS 6D Astrodon modded / 300 mm F4 L double setup.
          Experts will spot the many objects of the region: the Pelican and
          North America nebulae, the Cygnus loop (a supernova remnant), the
          Tulip nebula, the Crescent nebula and many more.
        </p>
      </section>
      <div id="galerie-image-interactive-texte-exifs">
        Double Canon EOS 6D Astrodon modded, Canon EF 300 mm F4 L set to F5, ISO
        3200. Mosaic of 12 images. Individual exposure time: 3 minutes, total
        exposure time: 31 hours.
      </div>
    </LayoutGalerieImageInteractive>
  )
}
